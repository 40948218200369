import { sword } from '@swordhealth/ui-corporate/dist/themes';

// TODO: Remove this after the migration to the new theme is done
const defaultColors = {
  ...sword.colors,
  red: '#F46262',
  dark_neutral: '#F9F2EA',
  darker_neutral: '#F1E0C9',
  blue: '#2972A8',
  dark_blue: '#1D3861',
  hackathon: {
    primary: '#1E595A',
    primary_medium: '#153436',
    primary_dark: '#102628',
    link_hover: '#ffffff99',
    error: '#FF9B75',
  },
};

const themeColors = {
  hackathon: {
    ...defaultColors.hackathon,
    bg: defaultColors.hackathon.primary_dark,
    navBg: defaultColors.hackathon.primary_dark,
    navColor: defaultColors.white,
    navColorScrolled: defaultColors.white,
    agendaNavBg: defaultColors.hackathon.primary_dark,
    agendaNavHoverColor: defaultColors.hackathon.link_hover,
    agendaNavActiveColor: defaultColors.grey.dark,
    hoursColor: defaultColors.white,
    textColor: defaultColors.white,
    labelColor: defaultColors.white,
    linkColor: defaultColors.white,
    linkHoverColor: defaultColors.hackathon.link_hover,
    titleColor: defaultColors.white,
    titleColorPrimary: defaultColors.white,
    primaryButtonColor: defaultColors.white,
    primaryButtonBg: defaultColors.hackathon.primary,
    primaryButtonHoverColor: defaultColors.grey.dark,
    primaryButtonHoverBg: defaultColors.white,
    secondaryButtonBorder: defaultColors.white,
    secondaryButtonHoverColor: defaultColors.white,
    secondaryButtonHoverBg: defaultColors.grey.dark,
    tertiaryButtonBorder: defaultColors.grey.dark,
    tertiaryButtonColor: defaultColors.grey.dark,
    tertiaryButtonBg: defaultColors.white,
    tertiaryButtonHoverBorder: defaultColors.grey.dark,
    tertiaryButtonHoverColor: defaultColors.white,
    tertiaryButtonHoverBg: 'rgba(255, 255, 255, 0.3)',
    whiteButtonHoverBg: 'rgba(255, 255, 255, 0.3)',
    highlightColor: defaultColors.grey.dark,
    errorColor: defaultColors.hackathon.error,
  },
  aiSummit: {
    bg: defaultColors.neutral[100],
    navBg: defaultColors.white,
    navColor: defaultColors.white,
    navColorScrolled: defaultColors.grey[800],
    agendaNavBg: defaultColors.white,
    agendaNavHoverColor: defaultColors.red,
    agendaNavActiveColor: defaultColors.red,
    hoursColor: defaultColors.grey.default,
    textColor: defaultColors.grey.dark,
    labelColor: defaultColors.grey.default,
    linkColor: defaultColors.red,
    linkHoverColor: defaultColors.grey.dark,
    titleColor: defaultColors.white,
    titleColorPrimary: defaultColors.grey.dark,
    primaryButtonColor: defaultColors.white,
    primaryButtonBg: defaultColors.red,
    primaryButtonHoverColor: defaultColors.white,
    primaryButtonHoverBg: defaultColors.grey.dark,
    secondaryButtonBorder: defaultColors.grey.dark,
    secondaryButtonHoverColor: defaultColors.white,
    secondaryButtonHoverBg: defaultColors.grey.dark,
    tertiaryButtonBorder: defaultColors.grey.dark,
    tertiaryButtonColor: defaultColors.white,
    tertiaryButtonBg: defaultColors.grey.dark,
    tertiaryButtonHoverBorder: defaultColors.grey.default,
    tertiaryButtonHoverColor: defaultColors.white,
    tertiaryButtonHoverBg: defaultColors.grey.default,
    whiteButtonHoverBg: defaultColors.grey[800],
    highlightColor: defaultColors.red,
    errorColor: defaultColors.hard_red,
  },
  vision: {
    bg: defaultColors.dark_neutral,
    navBg: defaultColors.white,
    navColor: defaultColors.white,
    navColorScrolled: defaultColors.grey.dark,
    agendaNavBg: defaultColors.darker_neutral,
    agendaNavHoverColor: defaultColors.red,
    agendaNavActiveColor: defaultColors.red,
    hoursColor: defaultColors.grey.default,
    textColor: defaultColors.grey.dark,
    labelColor: defaultColors.grey.default,
    linkColor: defaultColors.red,
    linkHoverColor: defaultColors.grey.dark,
    titleColor: defaultColors.white,
    titleColorPrimary: defaultColors.grey.dark,
    primaryButtonColor: defaultColors.white,
    primaryButtonBg: 'radial-gradient(farthest-corner at top, #e5092b, #2972a8 80%)',
    primaryButtonHoverColor: defaultColors.white,
    primaryButtonHoverBg: 'radial-gradient(farthest-corner at top, #e5092b, #2972a8 80%)',
    secondaryButtonBorder: defaultColors.grey.dark,
    secondaryButtonHoverColor: defaultColors.white,
    secondaryButtonHoverBg: defaultColors.grey.dark,
    tertiaryButtonBorder: defaultColors.grey.dark,
    tertiaryButtonColor: defaultColors.grey.dark,
    tertiaryButtonBg: defaultColors.white,
    tertiaryButtonHoverBorder: defaultColors.grey.dark,
    tertiaryButtonHoverColor: defaultColors.white,
    tertiaryButtonHoverBg: defaultColors.grey.dark,
    whiteButtonHoverBg: defaultColors.grey[800],
    highlightColor: defaultColors.red,
    errorColor: defaultColors.hard_red,
  },
};

const theme = {
  ...sword,
  colors: {
    ...defaultColors,
    ...themeColors,
  },

  fonts: {
    ...sword.typography,
    title: `var(--title-font, sans-serif)`,
    body: `var(--ui-font-family-body, sans-serif)`,
  },
};

export default theme;
