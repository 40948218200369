//@TODO: change openGraph url to a env var

const DefaultSEO = {
  defaultTitle: 'Sword Health',
  titleTemplate: '%s | Sword Health',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    site_name: 'Sword Health',
  },
};

const SEOMetadata = {
  url: 'https://swordhealth.com',
  linkedInProfile: 'https://www.linkedin.com/company/swordhealth',
};

export { DefaultSEO, SEOMetadata };
