import React, { useState, createContext } from 'react';
import { element } from 'prop-types';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [scrolled, setScrolled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <NavigationContext.Provider
      value={{
        scrolled,
        setScrolled,
        isOpen,
        setIsOpen,
        hover,
        setHover,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationContext.propTypes = {
  children: element.isRequired,
};
export default NavigationContext;
