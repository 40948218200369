import { createGlobalStyle } from 'styled-components';

import theme from '@/utils/styles-variables';

import ResetCss from './reset-css';

const GlobalStyle = createGlobalStyle`
  ${ResetCss}

  /*
  * Offset anchors from sticky header
  */
  :root {
    --header-height: 80px;
    --top-padding: 10px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      --header-height: 60px;
    }
  }
  
  body {
    font-family: ${theme.fonts.body};
    font-weight: var(--body-font-weight, 500);
    font-size: 16px;
    line-height: 26px;
    color: var(--text-color, ${theme.colors.grey.dark});
    overflow: hidden;
    
    h1 {
      font-weight: 800;
      font-size: 70px;
      line-height: 70px;
    }

    h2 {
      font-weight: 700;
      font-size: 55px;
      line-height: 65px;
    }

    h3 {
      font-weight: 800;
      font-size: 45px;
      line-height: 55px;
    }

    h4 {
      font-weight: 800;
      font-size: 30px;
      line-height: 45px;
    }
  }

  html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    scroll-margin-top: calc(var(--header-height) + var(--top-padding));
  }

  #nprogress .bar{
    height: 4px;
    background:${theme.colors.red};

    .peg {
      box-shadow:0 0 10px ${theme.colors.red}, 0 0 5px ${theme.colors.red};
    }
  }
`;

export default GlobalStyle;
