import type { AppProps } from 'next/app';
import Head from 'next/head';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { setConfiguration } from 'react-grid-system';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import { DefaultSEO, SEOMetadata } from '@/config/seo-meta.js';
import { NavigationProvider } from '@/contexts/navigation';
import GlobalStyle from '@/utils/global-styles';

import { gilmerFont, montserratFont, spaceMonoFont } from '../utils/fonts';

import theme from '@/utils/styles-variables';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

setConfiguration({
  containerWidths: [
    theme.breakpoints.min_sm,
    theme.breakpoints.min_md,
    theme.breakpoints.min_lg,
    theme.breakpoints.min_xl,
    theme.breakpoints.min_xxl,
    1810,
  ],
  defaultScreenClass: 'xl',
  maxScreenClass: 'xl',
  gutterWidth: 32,
});

export default function AscendApp({ Component, pageProps }: AppProps) {
  const eventTheme = (pageProps?.content?.theme || 'vision') as keyof typeof theme.colors;

  return (
    <div className={`${montserratFont.variable} ${gilmerFont.variable} ${spaceMonoFont.variable}`}>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <meta name="theme-color" content={theme.colors.red} />
      </Head>

      <DefaultSeo
        {...DefaultSEO}
        additionalMetaTags={[
          {
            property: 'theme-color',
            content: theme.colors.red,
          },
        ]}
        dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_ENV !== 'production'}
        dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_ENV !== 'production'}
      />
      <SocialProfileJsonLd
        type="Organization"
        name={DefaultSEO.openGraph.site_name}
        url={SEOMetadata.url}
        sameAs={[SEOMetadata.linkedInProfile]}
      />

      <StyleSheetManager enableVendorPrefixes>
        <ThemeProvider
          theme={{
            ...theme,
            eventTheme,
            eventColors: theme.colors?.[eventTheme] || {},
          }}
        >
          <GlobalStyle />
          <NavigationProvider>
            <Component {...pageProps} />
          </NavigationProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </div>
  );
}
